import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import IconFire from "../images/icon-fire-what-is-pmr.svg"
import IconBook from "../images/icon-book-what-is-pmr.svg"
import IconHeart from "../images/icon-heart-what-is-pmr.svg"
import IconNext from "../images/icon-next.png"
import IconArrowOne from "../images/icon-arrow-01-what-is-pmr.svg"
import IconArrowTwo from "../images/icon-arrow-02-what-is-pmr.svg"
import IconArrowThree from "../images/icon-arrow-03-what-is-pmr.svg"

import HexPmrPainDesktop from "../images/hex-pmr-pain-desktop.png"
import HexPmrPainMobile from "../images/hex-pmr-pain-mobile.png"
import HexIconsHeroHomeDesktop from "../images/hex-icons-home-hero-desktop.png"
import HexIconsHeroHomeMobile from "../images/hex-icons-home-hero-mobile.png"
import QuoteDesktop from "../images/quote-home-desktop.png"
import QuoteMobile from "../images/quote-home-mobile.png"

import ImageHero from "../images/hero-home.png"

const IndexPage = () => {

  const schema = {
    "@context": "https://schema.org/",
    "@type": "MedicalWebPage",
    "url": "https://www.pmrandyou.com/",
    "audience": "https://health-lifesci.schema.org/Patient",
    "specialty": "https://health-lifesci.schema.org/Rheumatologic",
    "about": {
      "@type": "MedicalCondition",
      "name": "Polymyalgia rheumatica ",
      "relevantSpecialty":  "https://health-lifesci.schema.org/Rheumatologic",
      "alternateName": "PMR",
      "signOrSymptom": [
        {   
          "@type": "MedicalSymptom",
          "name": "Joint swelling"
        },
        {   
          "@type": "MedicalSymptom",
          "name": "Morning stiffness"
        },
        {   
          "@type": "MedicalSymptom",
          "name": "Weakness"
        },
        {   
          "@type": "MedicalSymptom",
          "name": "Loss of appetite "
        },
        {   
          "@type": "MedicalSymptom",
          "name": "Weight loss"
        },
        {   
          "@type": "MedicalSymptom",
          "name": "Mild fever "
        }],
      "associatedAnatomy": 
      {
        "@type": "AnatomicalStructure",
        "name": "Joints, muscles, bones, and organs "
      }
    }  
  }

  return (
    <Layout>
      <Seo 
        title="PMR and You"
        desc="Learn about the inflammatory rheumatic disease polymyalgia rheumatica (PMR)."
        ogtitle="PMR and You | PMR and You Website"
        ogdesc="Learn about polymyalgia rheumatica (PMR)."
        schematag = {schema}
      />

      <div className="main-hero index">
        <div className="row">
          <div className="columns small-12 medium-12 large-6">
            <h1 className="headline-hero mb-2 md_mb-2">PMR and You</h1>
            <p>Polymyalgia rheumatica, or PMR, is an inflammatory rheumatic disease. Rheumatic diseases are diseases that cause your body's immune system to attack your:</p>
            <p className="mt-2 md_mt-2 lg_mb-4 show-for-large"><img src={HexIconsHeroHomeDesktop} alt="Joints Muscles Bones Organs" className="hex-icons-hero-home-animation" /></p>
          </div>
          <div className="columns small-6 medium-6 hide-for-large mb-4 text-center"><img src={HexIconsHeroHomeMobile} alt="Joints Muscles Bones Organs" className="hex-icons-hero-home-animation" /></div>
          <div className="columns small-6 medium-6 large-6 text-right large-text-center align-self-bottom">
            <img src={ImageHero} alt="blonde woman" id="hero-woman" />
          </div>
        </div>
      </div>

      <div className="content-container pb-4 md_pb-4">
        <p className="headline-body mb-1 md_mb-1">PMR is a common inflammatory rheumatic disease</p>
        <ul className="dark-red-bullets mb-2 md_mb-2">
          <li>PMR starts in the large joints of the <strong>shoulders, hips, and neck</strong></li>
        </ul>
        <div className="row lg_mb-3">
          <div className="columns small-12 medium-12 large-4 mb-2 with-arrow">
            <Link to="/what-is-pmr/">
              <div className="callout light-gray slate-border height-100pc" style={{ width: '95%', margin: '0 auto' }}>
                <p style={{ height: '105px', minHeight: '105px', maxHeight: '105px' }}><img src={IconFire} alt="flame icon"/></p>
                <p style={{ minHeight: '65px' }}>Learn about the<br className="show-for-large"/><span className="hide-for-large">&nbsp;</span>symptoms of PMR</p>
                <p><img src={IconArrowOne} alt="arrow icon" className="icon-arrow-animation" /></p>
              </div>
            </Link>
          </div>
          <div className="columns small-12 medium-12 large-4 mb-2 with-arrow">
            <Link to="/pmr-treatment-journey/">
              <div className="callout light-gray slate-border height-100pc" style={{ width: '95%', margin: '0 auto' }}>
                <p style={{ height: '105px', minHeight: '105px', maxHeight: '105px'}}><img src={IconBook} alt="book icon" style={{ marginTop: '30px' }}/></p>
                <p style={{ minHeight: '65px' }}>Read about PMR treatment</p>
                <p><img src={IconArrowTwo} alt="arrow icon" className="icon-arrow-animation" /></p>
              </div>
            </Link>
          </div>
          <div className="columns small-12 medium-12 large-4 mb-4 with-arrow">
            <Link to="/support-and-resources/">
              <div className="callout light-gray slate-border height-100pc" style={{ width: '95%', margin: '0 auto' }}>
                <p style={{ height: '105px', minHeight: '105px', maxHeight: '105px' }}><img src={IconHeart} alt="heart and hands icon"/></p>
                <p style={{ minHeight: '65px' }}>Build a PMR support system</p>
                <p><img src={IconArrowThree} alt="arrow icon" className="icon-arrow-animation" /></p>
              </div>
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="columns small-12 medium-12 large-6 align-self-middle text-center large-text-left">
            <img src={HexPmrPainDesktop} alt="PMR pain is not all in your head. PMR pain affects your daily life. It's not just about your body getting older." className="show-for-large" />
            <img src={HexPmrPainMobile} alt="PMR pain is not all in your head. PMR pain affects your daily life. It's not just about your body getting older." className="hide-for-large" />
          </div>
          <div className="columns small-12 medium-12 large-6 text-center">
            <div className="mt-3 mb-4 hide-for-large"><img src={QuoteMobile} alt="Is my life always going to be like this? --a real person with PMR" /></div>
            <div className="md_mb-2 show-for-large"><img src={QuoteDesktop} alt="Is my life always going to be like this? --a real person with PMR" /></div>
            <Link to="/what-is-pmr/" className="callout-link">
              <div className="callout next-page home row">
                <div className="columns small-12 medium-8 large-9 align-self-middle mb-2 md_mb-0">Learn about the symptoms of PMR</div>
                <div className="columns small-12 medium-4 large-3 align-self-middle"><img src={IconNext} alt="arrow icon" className="icon-next-arrow icon-next-arrow-animation" /></div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage